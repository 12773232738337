<template>
  <div :style="{ top: '-125px' }">
    <v-container>
      <v-row class="text-center">
        <v-col class="mb-4">
          <h1
            class="mb-10"
            :style="{ fontFamily: 'Poppins', fontSize: '50px' }"
          >
            We are Crypt-DNA
          </h1>

          <p class="subheading font-weight-light" :style="{ fontWeight: 100 }">
            The problem: As the metaverses expends we will need to have virtual
            selfs that represents ourselves and ça be use across the metaverses.
            We need to be identify and have a unique expression of self. <br />
          </p>
          <br />

          <p
            class="subheading font-weight-light"
            :style="{ fontFamily: 'Poppins', fontWeight: 100 }"
          >
            Introducing Crypt-DNA. Crypt dna block-chain, cross Muti verses
            technology provide unique and customisable image that are based on
            your DNA. Think of it like your virtual DNA. Will you be a women, a
            men or any gender you could think of. Our deep neural-network train
            on real DNA will produce unique caratheristic that can be use to
            confirm identity while allowing limitless customization.
          </p>
          <br />
          <v-timeline>
            <v-timeline-item  color="secondary"
              >2022 Q1 - Project lunch, website online.</v-timeline-item
            >
            <v-timeline-item small class="text-right" color="secondary">
              2022 Q3 - VIP CDNA pre-sell for our embassadors and early investor
            </v-timeline-item>
            <v-timeline-item small color="secondary"
              >2022 Q4 - CDNA-NFT sell start</v-timeline-item
            >
            <v-timeline-item small color="secondary" class="text-right"
              >2024 Q2 - CDNA - NFT unveiling</v-timeline-item
            >
            <v-timeline-item small color="secondary"
              >TBA - Integration with metaverses partners</v-timeline-item
            >
          </v-timeline>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-img src="@/assets/futur.jpg" height="300px"> </v-img>
    </v-row>
    <v-container>
      <v-row class="text-center">
        <v-col class="mb-4 mt-4">
          <h1 :style="{ fontFamily: 'Poppins' }">
            Out Mission: Help Mankind Evolve
          </h1>
          <br />
          <p
            class="subheading font-weight-light"
            :style="{ fontFamily: 'Poppins' }"
          >
            Crypt-DNA is one of the leading company that use genomic and
            block-chain technology to build personalized human for the
            metaverse.
          </p>
          <v-img src="@/assets/clogo.png"></v-img>
           <h1 :style="{ fontFamily: 'Poppins' }">
           How can I join, become an embassador?
          </h1>
          <p
            class="subheading font-weight-light"
            :style="{ fontFamily: 'Poppins' }"
          >
            It simple talk about our website on your social
            media platform and discords and our next generation Turing
            accelerated algorithm will find your contribution and will send an
            email when we are ready for the next phase.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col cols="9" class="pr-0">
        <v-img src="@/assets/dna.jpg" height="300px"></v-img>
      </v-col>
      <v-col cols="3" class="pl-0">
        <v-img src="@/assets/lab.jpg" height="300px"></v-img>
      </v-col>
    </v-row>
    <v-row class="text-center" :style="{ backgroundColor: '#eeeeee' }">
      <v-col cols="12">
        <h1 class="display-2 font-weight-bold mb-5 mt-5">Our Team</h1>
        <p>
          Crypt-DNA is a decentralizsed crypto medical startup. Our team of
          medical and technology visonary pioneer are hard at work all over the
          world.
        </p>
      </v-col>

      <v-col cols="12">
        <v-slide-group v-model="model" class="pa-4" center-active show-arrows>
          <v-slide-item :key="1" v-slot="{ active, toggle }">
            <div class="ma-4" width="128" @click="toggle">
              <v-avatar color="primary" size="128">
                <img src="@/assets/avatar-0.jpg" alt="Brian Green" />
              </v-avatar>
              <h4>Brian Green</h4>
              <h6>CEO</h6>
            </div>
          </v-slide-item>
          <v-slide-item :key="1" v-slot="{ active, toggle }">
            <div class="ma-4" width="128" @click="toggle">
              <v-avatar color="primary" size="128">
                <img src="@/assets/avatar-0a.jpg" alt="Sandra Patel" />
              </v-avatar>
              <h4>Sandra Patel</h4>
              <h6>CTO</h6>
            </div>
          </v-slide-item>

          <v-slide-item :key="2" v-slot="{ active, toggle }">
            <div class="ma-4" width="128" @click="toggle">
              <v-avatar color="primary" size="128">
                <img src="@/assets/avatar-2.jpg" alt="William Smith" />
              </v-avatar>
              <h4>William Smith</h4>
              <h6>Lead Architect</h6>
            </div>
          </v-slide-item>
          <v-slide-item :key="3" v-slot="{ active, toggle }">
            <div class="ma-4" width="128" @click="toggle">
              <v-avatar color="primary" size="128">
                <img src="@/assets/avatar-3.jpg" alt="Sarah Anderson" />
              </v-avatar>
              <h4>Sarah Anderson</h4>
              <h6>Head of Communication</h6>
            </div>
          </v-slide-item>
          <v-slide-item :key="5" v-slot="{ active, toggle }">
            <div class="ma-4" width="128" @click="toggle">
              <v-avatar color="primary" size="128">
                <img src="@/assets/avatar-5.jpg" alt="Kevin Patel" />
              </v-avatar>
              <h4>Kevin Patel</h4>
              <h6>Lead Lab Manager</h6>
            </div>
          </v-slide-item>
          <v-slide-item :key="6" v-slot="{ active, toggle }">
            <div class="ma-4" width="128" @click="toggle">
              <v-avatar color="primary" size="128">
                <img src="@/assets/avatar-6.jpg" alt="Jessica Johnson" />
              </v-avatar>
              <h4>Jessica Johnson</h4>
              <h6>Lead Reasearch & Developpment</h6>
            </div>
          </v-slide-item>
          <v-slide-item :key="1" v-slot="{ active, toggle }">
            <div class="ma-4" width="128" @click="toggle">
              <v-avatar color="primary" size="128">
                <img src="@/assets/avatar-1.jpg" alt="John Richmond" />
              </v-avatar>
              <h4>John Richman</h4>
              <h6>Lead Crupto-DNA scientist</h6>
            </div>
          </v-slide-item>
          <v-slide-item :key="4" v-slot="{ active, toggle }">
            <div class="ma-4" width="128" @click="toggle">
              <v-avatar color="primary" size="128">
                <img src="@/assets/avatar-4.jpg" alt="Steven Foster Jr" />
              </v-avatar>
              <h4>Steven Foster Jr</h4>
              <h6>Senior Dev</h6>
              
            </div>
          </v-slide-item>
        </v-slide-group>
        <br/>
        <br/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
    importantLinks: [
      {
        text: "Documentation",
        href: "https://vuetifyjs.com",
      },
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com",
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs",
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify",
      },
    ],
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com/components/api-explorer",
      },
      {
        text: "Select a layout",
        href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
      },
    ],
  }),
};
</script>
