<template>

  <div class="home">
    
<v-parallax
    class="test"
    dark
    src="../assets/dna-3539309.jpg"
    height="700"
  >
  <!-- <v-sheet
    color="rgb(0,0,0,0.5)"
    width="100%"
    height="100%"
    :style="{ display: 'flex'}"
    >
    <v-row
      align="center"
      justify="center"
      :style="{ color: 'white'}"
    >
      <v-col
        class="text-center"
        cols="12"
      >
      </v-col>
    </v-row>
  </v-sheet> -->
  </v-parallax>
 

    <HelloWorld msg="Welcome to Your Vue.js App"/>
    
       <v-footer
    dark
    padless
    :style="{width:'100%'}"
  >
    <v-card
      flat
      tile
      color="secondary"
      class="white--text text-center"
      width="100%"
      dark
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4 white--text"
          icon
          disabled
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>


      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Crypt-DNA Corp.</strong>
      </v-card-text>


      <v-divider></v-divider>
      <v-card-text class="white--text pt-0" :style="{ fontSize: '2px'}">
        *Crypt-DNA is a distopic joke to illustrate that people can be gullable and that the promise of something is sometime better than the thing itself.  Reasearch is advice for every project.  Note that the author of this site will not con and will not take money of people.  This will be use to raise awarness and discuss thruth on the internet.  -- Plpouple
      </v-card-text>

    </v-card>
  </v-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }),
}
</script>

<style lang="scss" scoped>
  .test {

  }


</style>
