import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "#00dee8", 
            secondary: "#007c82",
            background: "#eeeeee", // If not using lighten/darken, use base to return hex
          },
          dark: {
            primary: "#00dee8", 
            secondary: "#007c82",
            background: "#eeeeee", // If not using lighten/darken, use base to return hex
       
          },
        }
    }
});
